var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "buttonHolder",
      class: {
        secondStyle: _vm.secondStyle,
        "vertical niceScroll": _vm.verticalList,
      },
    },
    [
      _vm._l(_vm.options, function (option) {
        return _c(
          "div",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: option.tooltip,
                expression: "option.tooltip",
              },
            ],
            key: option.id,
            staticClass: "button",
            class: {
              selected: _vm.selectedOptions.includes(option.id),
              disabled: _vm.disabled || option.disabled,
              darkText: _vm.darkText,
              smaller: _vm.smaller,
              spaceLeft: option.space,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.selectOption(option)
              },
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.selectOption(option)
              },
            },
          },
          [
            option.disabled
              ? _c("disabled-icon", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: "" + option.disabled,
                      expression: "`${option.disabled}`",
                    },
                  ],
                  staticClass: "lockIcon",
                  attrs: { accessLevel: option.disabled },
                })
              : _vm._e(),
            _vm._v(" " + _vm._s(option.name) + " "),
            option.info
              ? _c("i", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: option.info,
                      expression: "option.info",
                    },
                  ],
                  staticClass: "icofont-info",
                })
              : _vm._e(),
            option.icon
              ? _c("i", { class: "icofont-" + option.icon })
              : _vm._e(),
          ],
          1
        )
      }),
      _vm.allowNoFilter && _vm.selectedOptions.length !== 0
        ? _c(
            "div",
            {
              staticClass: "button delete",
              class: { smaller: _vm.smaller },
              on: {
                click: _vm.clearOptions,
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.clearOptions.apply(null, arguments)
                },
              },
            },
            [_c("i", { staticClass: "icofont-ui-close" })]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }