<template>
	<div ref="table" class="relative">
        <div
            class="tableWrapper niceScroll"
            :class="{ curved: !sitsOnCard }"
            ref="scrollableTable"
            :style="{ maxHeight: `calc(100% - ${footerHeight}px)`, minHeight: minHeightIs100 ? `calc(100% - ${footerHeight}px)` : '200px' }"
            @scroll="tableScroll"
        >
            <table class="table">
                <genericHeaderFilter
                    class="filter"
                    :style="{ top: secondHeaders.length > 0 ? '75px' : '30px' }"
                    v-if="tableFilterBox.visible"
                    :config="tableFilterBox"
                    :allowSort="shouldSort"
                    @sortUpdate="sortUpdate"
                    @filterUpdate="filterUpdate"
                    @closeFilter="closeFilter"
                    @resetFilter="filterReset"
                />
                <thead>
                    <tr class="tr" style="padding: 10px">
                        <td v-show="tableDataData.length === 0" class="td">
                            {{ noDataMessage }}
                        </td>
                        <th
                            v-show="tableDataData.length !== 0 && getColSpan(header, key) !== 0"
                            v-for="(header, key) in firstHeadersCalculated"
                            class="th first fixedWidth"
                            :rowspan="header.isHeaderParent ? 1 : header.rowSpan"
                            :colspan="getColSpan(header, key)"
                            v-tooltip="{
                                content: header.hoverText,
                                disabled: header.hoverText == null
                            }"
                            :class="{
                                nowrap: true,
                                hoverable: header.allowFilter || header.allowSort,
                                clickable: header.allowFilter || header.allowSort,
                                disabled: (header.allowFilter || header.allowSort) && lockedAccount,
                                selectedRow: header.selected,
                                curveFirst: key === 0 && !sitsOnCard,
                                stickLeft: shouldStickLeft(header),
                            }"
                            :style="{ borderRight: header.borderRight, fontSize: `${header.fontSizePixels + 1}px` }"
                            :key="`header_${key}`"
                            @click="showFilter(header, $event)"
                        >
                            {{ getHeaderTitle(header) }}
                            <span
                                v-if="!header.isHeaderParent && headerToSort.id == header.id"
                                class="filterSort"
                                :class="headerToSort.ascending ? 'icofont-rounded-up' : 'icofont-rounded-down'"
                            />
                            <span
                                v-if="!header.isHeaderParent && headerToSort.id == header.id && showMapIcon"
                                v-tooltip="'Shown on map'"
                                class="filterSort"
                                :class="'icofont-google-map'"
                            />
                            <span
                                v-if="!header.isHeaderParent && currentFiltersByHeading.hasOwnProperty(header.id) && !currentFiltersByHeading[header.id].noFilter"
                                class="filterSort" :class="'icofont-filter'"
                            />
                        </th>
                    </tr>
                    <tr class="tr">
                        <th class="th second fixedWidth" v-if="showRowSelectors && secondHeaders.length > 0"></th>
                        <th
                            v-show="tableDataData.length !== 0"
                            v-for="(header, key) in secondHeaders"
                            class="th second fixedWidth"
                            v-tooltip="{
                                content: header.hoverText,
                                disabled: header.hoverText == null
                            }"
                            :class="{
                                nowrap: true,
                                hoverable: header.allowFilter || header.allowSort,
                                clickable: header.allowFilter || header.allowSort,
                                disabled: (header.allowFilter || header.allowSort) && lockedAccount,
                                selectedRow: header.selected,
                                'stickLeft': shouldStickLeft(header),
                                lastChild: header.parentHeaderId && (key === secondHeaders.length - 1 || header.parentHeaderId !== secondHeaders[key + 1].parentHeaderId)
                            }"
                            :style="{ borderRight: header.borderRight, fontSize: `${header.fontSizePixels + 1}px`, top: `${firstHeadersUnfiltered[0].fontSizePixels + 11}px` }"
                            :key="`header_${key}`"
                            @click="showFilter(header, $event)"
                        >
                            {{ getHeaderTitle(header) }}
                            <span
                                v-if="headerToSort.id == header.id"
                                class="filterSort"
                                :class="headerToSort.ascending ? 'icofont-rounded-up' : 'icofont-rounded-down'"
                            />
                            <span
                                v-if="!header.isHeaderParent && headerToSort.id == header.id && showMapIcon"
                                v-tooltip="'Shown on map'"
                                class="filterSort"
                                :class="'icofont-google-map'"
                            />
                            <span
                                v-if="currentFiltersByHeading.hasOwnProperty(header.id) && !currentFiltersByHeading[header.id].noFilter"
                                class="filterSort" :class="'icofont-filter'"
                            />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        class="tr"
                        v-for="(tableItem, key) in filteredData.slice(startToShow, endToShow)"
                        :key="`row_${key}`"
                        :class="{ hoverable: showHover, showCursor: showCursor, selected: tableItem.rowSelected && showRowSelectors }"
                        v-tooltip="{ content: tableItem.hoverOver, disabled: tableItem.hoverOver == null }"
                        @click="rowClicked(tableItem, el)"
                    >
                        <td v-if="showRowSelectors">
                            <p-check class="p-icon p-fill p-smooth" :key="filteredData.length" v-model="tableItem.rowSelected" @change="el => selectRow(tableItem, el)">
                                <i slot="extra" class="icon icofont-check"></i>
                            </p-check>
                        </td>
                        <td
                            :class="{
                                selectedRow: tableItem.selected,
                                nowrap: !item2.allowWrap,
                                'stickLeft': item2.freeze,
                                'flex-between': item2.isPlant,
                                'flash1': item2.flash && item2.flashCounter % 2 === 0,
                                'flash2': item2.flash && item2.flashCounter % 2 !== 0,
                                'td light': lightHover,
                                'iconHolder': item2.valueToIcons
                            }"
                            :style="{
                                borderRight: item2.borderRight,
                                color: getTextColour(item2),
                                backgroundColor: getBackgroundColour(item2),
                            }"
                            v-tooltip="{
                                content: item2.tooltip,
                                disabled: item2.tooltip == null
                            }"
                            class="td"
                            v-for="(item2, key) in orderedRow(tableItem.row)"
                            :key="`cell_${key}`"
                        >
                            <div v-if="item2.percent" :style="{ backgroundImage: getBackgroundImage(item2) }" style="width: 100%; height: 5px; margin-bottom: 2px; margin-top: -7px"></div>
                            <span v-if="item2.valueToIcons">
                                <span v-for="(icon, key) in getIcons(item2)" :key="key" :class="`iconCell ${icon.icon}`" :style="{ color: getIconTextColour(item2, icon.value), 'padding-right': '4px' }" />
                            </span>
                            <span v-else v-html="getRealValue(item2, true, false)" />
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="capped" v-if="showMoreData">
                Data displayed is capped. Find out how to access the full list <a href="http://lcpenact.com" target="_blank" class="link">here</a>.
            </div>
        </div>
        <div class="tableFooter hidden-sm hidden-xs" :class="{ floatingFooter: floatingFooter }" :style="{ height: footerHeight + 'px' }">
            <div>
                <slot name="footerLeft"></slot>
            </div>
            <div class="defaultFooter">
                <span>
                    <span class="icofont-ui-edit editDownloadIcon" @click.stop="setTableFilter" v-if="showFooterInternal && !lockedAccount"></span>
                    <genericTableFilter v-show="showTableFilter" :internalFooterTeleport="internalFooterTeleport"
                        :disableReorder="disableReorder" :tableUnit="unitLocationOverride"
                        :firstHeaders="firstHeadersUnfiltered" :secondHeaders="secondHeadersUnfiltered" :headersToHide="columnsToHide"
                        @orderChange="headerOrderChange" @tableUnitValue="tableUnitChange" @resetAllHeaderFilters="resetAllHeaderFilters"
                        @close="showTableFilter = false" @hide="setColumnsToHide" />
                </span>
                <component :is="internalFooterTeleport ? 'portal' : 'span'" to="tableDownload">
                    <download-excel
                        :fetch="getExcelData"
                        :type="$store.state.user.excelAsCsv ? 'csv' : 'xls'"
                        worksheet="Data"
                        :name="`${excelFilename} at ${this.$moment().format()}${$store.state.user.excelAsCsv ? '.csv' : '.xls'}`"
                        v-if="(internalFooterTeleport || showFooterInternal) && allowExcel && tableDataData.length !== 0"
                    >
                        <font-awesome-icon @click="showInputSelector = true"
                            :icon="['fas', 'download']"
                            class="editDownloadIcon"
                            v-tooltip="'Download table'"
                            aria-label="Download table"
                        />
                    </download-excel>
                </component>
            </div>
        </div>
	</div>
</template>

<script>
import Vue from 'vue';
import PortalVue from 'portal-vue';

import JsonExcel from 'vue-json-excel';
import genericHeaderFilter from '@/components/table/genericHeaderFilter';
import genericTableFilter from '@/components/table/genericTableFilter';
import { DatePickerMethods } from '@/components/inputs/datePicker/datePickerMethods';
import clone from 'lodash/clone';
Vue.component('downloadExcel', JsonExcel);
Vue.use(PortalVue);

export default {
    name: 'genericCSharpTable',
    components: {
        genericHeaderFilter,
        genericTableFilter,
    },
    props: {
        uniqueNameId: {
            type: String,
            required: true,
        },
        hideEntryOverride: {
            type: Object,
            required: false,
        },
        noDataMessage: {
            type: String,
            required: true,
        },
        excelFilename: {
            type: String,
            required: true,
        },
        tableData: {
            type: Object,
            required: true,
        },
        showHover: {
            type: Boolean,
            required: false,
            default: true,
        },
        allowExcel: {
            type: Boolean,
            required: false,
            default: true,
        },
        fromDate: {
            type: Date,
            required: false,
            default: () => new Date(),
        },
        toDate: {
            type: Date,
            required: false,
            default: () => new Date(),
        },
        backendPredefinedFilters: {
            type: Object,
            required: false,
        },
        shouldSort: {
            type: Boolean,
            required: false,
            default: true,
        },
        minHeightIs100: {
            type: Boolean,
            required: false,
            default: false,
        },
        showRowSelectors: {
            type: Boolean,
            required: false,
            default: false,
        },
        sitsOnCard: {
            type: Boolean,
            required: false,
            default: false,
        },
        showFooter: {
            type: Boolean,
            required: false,
            default: true,
        },
        showCursor: {
            type: Boolean,
            required: false,
            default: false,
        },
        disableReorder: {
            type: Boolean,
            required: false,
            default: false,
        },
        showMapIcon: {
            type: Boolean,
            required: false,
            default: false,
        },
        disableFilter: {
            type: Boolean,
            required: false,
            default: false,
        },
        disableSort: {
            type: Boolean,
            required: false,
            default: false,
        },
        showMoreData: {
            type: Boolean,
            required: false,
            default: false,
        },
        frontendPredefinedFilters: {
            type: Object,
            required: false,
        },
        lightHover: {
            type: Boolean,
            required: false,
            default: false,
        },
        internalFooterTeleport: {
            type: Boolean,
            required: false,
            default: false,
        },
        floatingFooter: {
            type: Boolean,
            required: false,
            default: false,
        },
        hidePoundDecimals: {
            type: Boolean,
            required: false,
            default: false,
        },
        threeSigFigs: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    async created () {
        if (this.backendPredefinedFilters && this.preDefinedFilters == null) {
            if (this.backendPredefinedFilters.headerToSort) this.headerToSort = this.backendPredefinedFilters.headerToSort;
            if (this.backendPredefinedFilters.currentFiltersByHeading) this.currentFiltersByHeading = this.backendPredefinedFilters.currentFiltersByHeading;
            if (this.backendPredefinedFilters.columnsToHide) this.columnsToHide = this.backendPredefinedFilters.columnsToHide;

            this.saveFiltersInDb();
        }

        if (this.frontendPredefinedFilters) {
            if (this.frontendPredefinedFilters.headerToSort) this.headerToSort = this.frontendPredefinedFilters.headerToSort;
            if (this.frontendPredefinedFilters.currentFiltersByHeading) this.currentFiltersByHeading = this.frontendPredefinedFilters.currentFiltersByHeading;

            this.saveFiltersInDb();
        }

        if (this.preDefinedFilters != null) {
            if (this.preDefinedFilters.hasOwnProperty('currentFiltersByHeading')) {
                this.currentFiltersByHeading = this.preDefinedFilters.currentFiltersByHeading;
                const hasBingoGroup = Object.values(this.currentFiltersByHeading).some(filter => filter.options.group === 'bingoEnact' || filter.options.group === 'notBingoEnact');
                if (hasBingoGroup && Object.keys(this.$store.state.data.pages.bingo.data.plantData).length === 0) {
                    await this.$store.dispatch('getBingoCardData');
                }
            }
            if (this.preDefinedFilters.hasOwnProperty('headerToSort')) {
                this.headerToSort = this.preDefinedFilters.headerToSort;
            }
            if (this.preDefinedFilters.hasOwnProperty('columnsToHide')) {
                this.columnsToHide = this.preDefinedFilters.columnsToHide;
            }
            if (this.preDefinedFilters.hasOwnProperty('orderedHeaders')) {
                this.orderedHeaders = this.preDefinedFilters.orderedHeaders;
            }
            if (this.preDefinedFilters.hasOwnProperty('unitLocation')) {
                this.unitLocationOverride = this.preDefinedFilters.unitLocation;
            }
        }
        this.$emit('filterChange', this.currentFiltersByHeading);
    },
    data () {
        return {
            tableFilterBox: {
                visible: false,
                headerData: {},
                uniqueData: [],
                sampleDataPoint: null,
                currentFilters: {},
                currentSortAsc: null,
                selectedOptions: [],
                position: { left: 0, top: 0 },
            },
            numberOfScrollDowns: 0,
            startToShow: 0,
            currentHeaderOpen: '',
            currentFiltersByHeading: {},
            headerToSort: { id: '', ascending: false },
            selectedPlantId: '',
            selectedRows: [],
            showTableFilter: false,
            columnsToHide: [],
            orderedHeaders: [],
            unitLocationOverride: 'inherit',
        };
    },
    computed: {
        loggedIn () {
            return !this.$store.state.ui.isFreeUser;
        },
        userSettings () {
            return this.$store.state.user;
        },
        timeFormatValue () {
            return this.userSettings.timeFormatValue;
        },
        tableUnitValue () {
            return this.unitLocationOverride === 'inherit' ? this.userSettings.tableUnitValue : this.unitLocationOverride;
        },
        lockedAccount () {
            return this.userSettings.lockedAccount === 'editLocked';
        },
        showFooterInternal () {
            return this.showFooter && this.tableDataData.length !== 0;
        },
        footerHeight () {
            return this.showFooterInternal && !this.floatingFooter ? 25 : 0;
        },
        endToShow () {
            return Math.min(this.tableDataData.length, 500 + this.numberOfScrollDowns * 100);
        },
        preDefinedFilters () {
            return this.uniqueNameId === '' ? null : this.$store.state.user.genericTableFilters[this.uniqueNameId];
        },
        tableDataData () {
            // this.resetFilters = true;
            return this.tableData.data;
        },
        firstHeadersCalculated () {
            const headersInOrder = [];

            if (this.showRowSelectors && this.secondHeaders.length === 0) {
                headersInOrder.push({
                    id: 'Selector',
                    title: '',
                    rowSpan: 2,
                    isHeaderParent: false,
                    borderRight: 0,
                });
            }

            this.firstHeadersUnfiltered.forEach(firstHeader => {
                if (!firstHeader.isHeaderParent && !this.columnsToHide.includes(firstHeader.id)) {
                    headersInOrder.push(firstHeader);
                }
            });
            let headerToAdd = null;
            this.secondHeaders.forEach(secondHeader => {
                if (headerToAdd === null || secondHeader.parentHeaderId !== headerToAdd.id) {
                    headerToAdd = clone(this.firstHeadersUnfiltered.find(el => el.id === secondHeader.parentHeaderId));
                    headerToAdd.columnSpan = 1;
                    headersInOrder.push(headerToAdd);
                } else {
                    headerToAdd.columnSpan++;
                }
            });
            return headersInOrder;
        },
        firstHeadersUnfiltered () {
            let headers = this.tableData.headerFirstRowById == null ? [] : Object.values(this.tableData.headerFirstRowById);
            if (this.orderedHeaders.length > 0) headers = headers.sort((a, b) => this.orderedHeaders.indexOf(a.id) - this.orderedHeaders.indexOf(b.id));
            return headers;
        },
        secondHeadersUnfiltered () {
            let headers = this.tableData.headerSecondRow == null ? [] : Object.values(this.tableData.headerSecondRow);
            if (this.orderedHeaders.length > 0) headers = headers.sort((a, b) => this.orderedHeaders.indexOf(a.id) - this.orderedHeaders.indexOf(b.id));
            return headers;
        },
        secondHeaders () {
            let headers = this.secondHeadersUnfiltered;
            if (this.columnsToHide.length > 0) headers = headers.filter(el => !this.columnsToHide.includes(el.id));
            return headers;
        },
        filteredData () {
            const currentFilters = this.currentFiltersByHeading;
            const selectedRows = [];
            const filterData = this.tableDataData.filter((row) => {
                if (row.hasOwnProperty('hideRow') && row.hideRow) return false;

                if (this.showRowSelectors && row.rowSelected) selectedRows.push(row);
                for (const heading of Object.keys(row.row)) {

                    let headerFound = this.findHeader(heading);

                    if (headerFound && !headerFound.allowFilter) continue;

                    if (currentFilters.hasOwnProperty(heading)) {
                        const options = currentFilters[heading].options;
                        const matchFound = this.cellsInFilters(currentFilters[heading].type, row.row[heading].values, options);
                        if (!matchFound) return false;
                    } else {
                        const lowerHeader = heading.charAt(0).toLowerCase() + heading.slice(1);
                        if (currentFilters.hasOwnProperty(lowerHeader)) {
                            const options = currentFilters[lowerHeader].options;
                            const matchFound = this.cellsInFilters(currentFilters[lowerHeader].type, row.row[heading].values, options);
                            if (!matchFound) return false;
                        }
                    }
                }
                return true;
            });

            if (this.showRowSelectors) {
                this.selectedRows = selectedRows;
                this.$emit('rowsSelected', this.selectedRows);
            }

            if (this.shouldSort && filterData.length !== 0) {
                if (this.headerToSort.id == null && this.headerToSort.titleLowerCase != null) this.headerToSort.id = this.headerToSort.titleLowerCase; // Old DB way to new

                if (this.headerToSort.id === '' || (this.firstHeadersUnfiltered.find(el => el.id === this.headerToSort.id) == null && this.secondHeaders.find(el => el.id === this.headerToSort.id) == null)) {
                    this.headerToSort.id = this.tableData.headerToSort;
                    this.headerToSort.ascending = this.tableData.ascending;
                }

                const dataToSort = filterData[0].row[this.headerToSort.id];
                if (dataToSort == null) {
                    const b = 1;
                } else {
                    this.customSort(dataToSort.cellType, filterData, x => x.row[this.headerToSort.id].values, true);
                }
            }
            this.$emit('dataLoaded', filterData);
            return filterData;
        },
    },
    methods: {
        findHeader (headerId) {
            let headerFound = this.firstHeadersUnfiltered.filter(
                (el) => el.id === headerId,
            );

            if (headerFound.length === 0) {
                headerFound = this.secondHeaders.filter(
                    (el) => el.id === headerId,
                );
            }

            if (headerFound.length == 0) return null;
            return headerFound[0];
        },
        getPlantGroupPlants (group) {
            if (group == null) {
                return {
                    plants: [],
                    inverse: false,
                };
            }

            switch (group) {
            case 'bingoEnact':
            case 'notBingoEnact':
                const plants = Object.keys(this.$store.state.data.pages.bingo.data.plantData);
                return {
                    plants,
                    inverse: group === 'notBingoEnact',
                };
            default:
                return {
                    plants: this.$store.state.user.chosenPlantsByGroup[group],
                    inverse: false,
                };
            }
        },
        orderedRow (row) {
            let rowKeys;

            if (this.orderedHeaders.length > 0) {
                rowKeys = Object.keys(row).sort((a, b) => this.orderedHeaders.indexOf(a) - this.orderedHeaders.indexOf(b));
            } else {
                rowKeys = Object.keys(row);
            }

            if (this.columnsToHide.length > 0) rowKeys = rowKeys.filter(el => !this.columnsToHide.includes(el));

            return rowKeys.map(key => row[key]);
        },
        headerOrderChange (headers) {
            this.orderedHeaders = headers;
            this.saveFiltersInDb();
        },
        tableUnitChange (unitLocation) {
            this.unitLocationOverride = unitLocation;
            this.saveFiltersInDb();
        },
        shouldStickLeft (header) {
            if (this.filteredData.length > 0 && !header.isHeaderParent) {
                const firstEl = this.filteredData.find(el => el.row.hasOwnProperty(header.id));
                if (firstEl) {
                    return firstEl.row[header.id].freeze;
                }
            }
            return false;
        },
        getExcelData () {
            const excel = this.filteredData.map((el) => {
                const row = {};
                Object.keys(el.row).forEach((id) => {
                    if (!el.row[id].noExcel) {
                        let newString = id;
                        newString = newString.replace('.', '');
                        row[newString] = this.getRealValue(el.row[id], false, true);
                    }
                });
                return row;
            });
            return excel;
        },
        setTableFilter () {
            if (this.lockedAccount) return;

            this.showTableFilter = true;
        },
        getColSpan (header, key) {
            if (header.isHeaderParent) return this.showRowSelectors && key === 0 ? header.columnSpan + 1 : header.columnSpan;
            else return 1;
        },
        setColumnsToHide (cols) {
            this.columnsToHide = cols;
            this.saveFiltersInDb();
        },
        customSort (cellType, toSort, getValueFromObject, sortInner) {
            if (cellType === 'date' || cellType === 'number') {
                toSort.sort((a, b) => {
                    let aSort = getValueFromObject(this.headerToSort.ascending ? a : b);
                    let bSort = getValueFromObject(this.headerToSort.ascending ? b : a);

                    if (aSort === undefined) {
                        if (bSort === undefined) return 0;
                        return this.headerToSort.ascending ? 1 : -1;
                    } else if (bSort === undefined) {
                        return this.headerToSort.ascending ? -1 : 1;
                    }

                    if (sortInner) {
                        if (aSort.length > 1) this.customSort(cellType, this.shallowCloneList(aSort), x => x, false);
                        aSort = aSort[0];
                        if (bSort.length > 1) this.customSort(cellType, this.shallowCloneList(bSort), x => x, false);
                        bSort = bSort[0];
                    }

                    const aSortNo = aSort == null || isNaN(aSort);
                    const bSortNo = bSort == null || isNaN(bSort);
                    if (aSortNo && bSortNo) return 0;
                    if (aSortNo) return -1;
                    if (bSortNo) return 1;

                    return aSort - bSort;
                });
            } else {
                toSort.sort((a, b) => {
                    let aSort = getValueFromObject(a);
                    let bSort = getValueFromObject(b);

                    if (sortInner) {
                        if (aSort.length > 1) this.customSort(cellType, this.shallowCloneList(aSort), x => x, false);
                        aSort = aSort[0];
                        if (bSort.length > 1) this.customSort(cellType, this.shallowCloneList(bSort), x => x, false);
                        bSort = bSort[0];
                    }

                    if (aSort > bSort) return 1;
                    return bSort > aSort ? -1 : 0;
                });
                if (this.headerToSort.ascending) toSort.reverse();
            }
        },
        getHeaderTitle (header) {
            let prefix = '';
            let suffix = '';

            if (this.tableUnitValue === 'header' && !header.multipleUnits) {
                if (header.prefixOverrideWhenInHeader || header.suffixOverrideWhenInHeader) {
                    prefix = header.prefixOverrideWhenInHeader || '';
                    suffix = header.suffixOverrideWhenInHeader || '';
                } else {
                    if (this.filteredData.length > 0) {
                        let rowFound;
                        const firstEl = this.filteredData.find(el => {
                            const keyFound = Object.keys(el.row).find(key => key.includes(header.id));
                            rowFound = el.row[keyFound];
                            return keyFound != null;
                        });

                        let headerShowUnits = true;
                        if (header.isHeaderParent) {
                            headerShowUnits = Object.keys(this.filteredData[0].row).every(key => {
                                const value = this.filteredData[0].row[key];
                                const thisKeyHeader = this.tableData.headerSecondRow.find(el => el.id === key);
                                return thisKeyHeader == null || thisKeyHeader.headerParentId !== header.id || (rowFound.prefix === value.prefix && rowFound.suffix === value.suffix);
                            });
                        }
                        if (header.parentHeaderId != null) {
                            // const parentHeader = this.tableData.headerFirstRowById[header.headerParentId];
                            headerShowUnits = Object.keys(this.filteredData[0].row).some(key => {
                                const value = this.filteredData[0].row[key];
                                const thisKeyHeader = this.tableData.headerSecondRow.find(el => el.id === key);
                                if (headerShowUnits == "Wholesale Volume - Total")
                                {
                                    let banana = 1;
                                }
                                try {
                                    return thisKeyHeader != null && thisKeyHeader.parentHeaderId === header.parentHeaderId && (rowFound.prefix !== value.prefix || rowFound.suffix !== value.suffix);
                                } catch (e) {
                                    let banana = 1;
                                }
                            });
                        }

                        if (firstEl && headerShowUnits) {
                            prefix = rowFound.prefix || '';
                            suffix = rowFound.suffix || '';
                        }
                    }
                }
            }

            if (header.isMw) {
                const capInMwh = this.$store.state.user.leaderboardCapacityInMwh;
                suffix = suffix.replace(capInMwh ? 'MW' : 'MWh', capInMwh ? 'MWh' : 'MW');
            }
            const prefixSuffixAdder = prefix === '' && suffix === '' ? '' : ` (${prefix}${suffix})`;
            return `${header.title}${prefixSuffixAdder}`;
        },
        shallowCloneList (list) {
            const newList = [];
            list.forEach(item => newList.push(item));
            return newList;
        },
        cellsInFilters (filterType, cellValues, filter) {
            switch (filterType) {
            case 'text':
                if (filter.values.length !== 0 || filter.group != null) {
                    const selectedGroupPlants = this.getPlantGroupPlants(filter.group);
                    const doInverseSearch = filter.exclude || (filter.group != null && selectedGroupPlants.inverse);
                    const toSearch = filter.group != null ? selectedGroupPlants.plants : filter.values;

                    let matchFound = doInverseSearch;
                    toSearch.forEach((filter) => {
                        if (cellValues != null) {
                            cellValues.forEach((rowEntryInList) => {
                                if (rowEntryInList === filter) matchFound = !doInverseSearch;
                            });
                        } else {
                            if (doInverseSearch) matchFound = true;
                        }
                    });
                    return matchFound;
                }
                break;
            case 'numeric':
                let matchFound = false;
                if (cellValues != null) {
                    cellValues.forEach(cellValue => {
                        if (!matchFound) {
                            let numericValue = parseFloat(cellValue);
                            if (filter.absolute) numericValue = Math.abs(numericValue);
                            if (isNaN(numericValue)) numericValue = 0;

                            const lowerBoundCriteria = filter.min == null || numericValue >= filter.min;
                            const upperBoundCriteria = filter.max == null || numericValue <= filter.max;

                            matchFound = lowerBoundCriteria && upperBoundCriteria;
                        }
                    });
                }
                return matchFound;
            case 'date':
                let dateFound = true;
                if (cellValues != null) {
                    cellValues.forEach(cellValue => {
                        const dateNumericValue = parseFloat(cellValue);
                        const { from, to } = this.getDateFromConfig(filter);
                        if (
                            (from != null && !isNaN(dateNumericValue) && dateNumericValue < from.valueOf() / 1000)
                                || (to != null && !isNaN(dateNumericValue) && dateNumericValue > to.valueOf() / 1000)
                        ) {
                            dateFound = false;
                        }
                    });
                } else {
                    dateFound = false;
                }
                return dateFound;
            }
            return null;
        },
        getDateFromConfig (options) {
            // IF !isNaN, then it's a number
            if ((options.hasOwnProperty('min') && !isNaN(options.min)) || (options.hasOwnProperty('max') && !isNaN(options.max))) {
                // OLD VERSION
                options.date = {
                    from: { type: 'date', value: options.min * 1000 },
                    to: { type: 'date', value: options.max * 1000 },
                    recentHour: false,
                    fromHours: 1,
                    toHours: 1,
                };
                delete options.min;
                delete options.max;
            }

            if (options.hasOwnProperty('date')) {
                // NEW VERSION
                return DatePickerMethods.getDatesFromConfig(options.date);
            }

            options.date = {
                from: { type: 'none', value: null },
                to: { type: 'none', value: null },
                recentHour: false,
                fromHours: 1,
                toHours: 1,
            };
            return { min: null, max: null };
        },
        tableScroll ({ target: { scrollTop, clientHeight, scrollHeight } }) {
            if (scrollTop + clientHeight + 10 >= scrollHeight) {
                this.numberOfScrollDowns++;
            }
        },
        saveFiltersInDb () {
            if (this.uniqueNameId !== '') {
                const storedFilterSettings = {
                    currentFiltersByHeading: this.currentFiltersByHeading,
                    headerToSort: this.headerToSort,
                    columnsToHide: this.columnsToHide,
                    orderedHeaders: this.orderedHeaders,
                    unitLocation: this.unitLocationOverride,
                };
                if (this.loggedIn) this.$store.dispatch('user/setGenericTableFilters', { name: this.uniqueNameId, filters: storedFilterSettings });
            }
        },
        async filterUpdate (range) {
            const hasBingoGroup = range.options.group === 'bingoEnact' || range.options.group === 'notBingoEnact';
            if (hasBingoGroup && Object.keys(this.$store.state.data.pages.bingo.data.plantData).length === 0) {
                await this.$store.dispatch('getBingoCardData');
            }
            if (this.currentFiltersByHeading.hasOwnProperty(this.currentHeaderOpen)) {
                const options = this.currentFiltersByHeading[this.currentHeaderOpen].options;
                const type = this.currentFiltersByHeading[this.currentHeaderOpen].type;
                if ((type === 'numeric' || options.type === 'date')
					&& options.min === range.options.min
                    && options.max === range.options.max
                    && options.absolute === range.options.absolute
                ) {
                    return;
                }
                if (type === 'text' && options.values === range.options.values && options.exclude === range.options.exclude) {
                    return;
                }
                if (type === 'text' && options.group != null && options.group === range.options.group) {
                    return;
                }
            }
            if (!range.noFilter) {
                Vue.set(this.currentFiltersByHeading, this.currentHeaderOpen, range);
            } else {
                Vue.delete(this.currentFiltersByHeading, this.currentHeaderOpen);
            }
            this.$emit('filterChange', this.currentFiltersByHeading);
            this.saveFiltersInDb();
        },
        filterReset () {
            const currentFiltersByHeading = { ...this.currentFiltersByHeading };
            delete currentFiltersByHeading[this.currentHeaderOpen];
            this.currentFiltersByHeading = currentFiltersByHeading;
            this.$emit('filterChange', this.currentFiltersByHeading);
            this.saveFiltersInDb();
        },
        resetAllHeaderFilters () {
            const currentFiltersByHeading = { ...this.currentFiltersByHeading };
            Object.keys(currentFiltersByHeading).forEach(header => {
                delete currentFiltersByHeading[header];
            })
            this.currentFiltersByHeading = currentFiltersByHeading;
            this.$emit('filterChange', this.currentFiltersByHeading);
            this.saveFiltersInDb();
        },
        sortUpdate (sort) {
            this.headerToSort.id = this.currentHeaderOpen;
            this.headerToSort.ascending = sort;
            this.saveFiltersInDb();
            this.$emit('plotOnMap', this.headerToSort.id);
        },
        shouldCellBeHidden (cell) {
            if (this.hideEntryOverride == null) {
                return cell.hideEntry;
            }
            return this.hideEntryOverride.hideEntry && cell.hideEntry;
        },
        getTextColour (cell) {
            if (this.shouldCellBeHidden(cell)) return cell.backgroundColour;
            return cell.textColour;
        },
        getBackgroundColour (cell) {
            if (cell.colourIdOverride && this.$store.state.user.bingoColourOverrides.hasOwnProperty(cell.colourIdOverride)) return this.$store.state.user.bingoColourOverrides[cell.colourIdOverride];
            let bgColour = cell.backgroundColour;
            if (cell.isPlant) {
                if (this.$store.state.user.plantGroupColourOverride
                    && this.$store.state.user.chosenPlantsByGroup[this.$store.state.user.chosenPlantGroupName].includes(cell.values[0])) {
                    bgColour = `${this.$store.state.user.chosenPlantGroupColour} !important`;
                }
            }
            return bgColour;
        },
        getBackgroundImage (cell) {
            if (!isNaN(parseFloat(cell.percent)) && cell.percent !== 0) {
                const percent = Math.round(cell.percent);
                const colour = cell.percentColour ? cell.percentColour : "green";
                return percent > 0 ?
                    `linear-gradient(to right, ${colour} ${percent}%, transparent 0%) !important`
                    : undefined;
            }
        },
        getIcons (cell) {
            const icons = [];
            Object.keys(cell.valueToIcons).forEach(cellValue => {
                cell.values.forEach(toFind => {
                    if (toFind.toLowerCase() === cellValue.toLowerCase()) icons.push({value: cellValue, icon: `icofont-${cell.valueToIcons[cellValue]}`});
                });
            });
            return icons;
        },
        getIconTextColour (cell, value) {
            if (cell.valueToColours && cell.valueToColours.hasOwnProperty(value)) return cell.valueToColours[value];
            return '';
        },
        getRealValue (cell, suffixPrefix, isExcel) {
            if (this.shouldCellBeHidden(cell) && !isExcel) {
                return '-';
            }
            // if selected to use the hide override, you are trying to see the alternative text
            const useAlternativeString = (this.hideEntryOverride != null && this.hideEntryOverride.hideEntry == false && cell.alternativeHiddenString != null);

            let valuesToConsider = [];
            if (useAlternativeString) {
                valuesToConsider.push(cell.alternativeHiddenString);
            } else if (cell.hasOwnProperty('value')) {
                valuesToConsider.push(cell.value);
            } else {
                if (cell.hasOwnProperty('value')) {
                    valuesToConsider.push(cell.value);
                } else {
                    valuesToConsider = cell.values;
                }
            }
            if (valuesToConsider == null || valuesToConsider[0] == null) return '-';

            let stringToShow = '';
            const cellValuesLength = valuesToConsider.length;
            valuesToConsider.forEach((value, index) => {
                const toAdd = this.getIndividualCellComponentValue(cell.cellType, value, cell, suffixPrefix, isExcel);
                stringToShow += toAdd;
                if (index === 0 && cellValuesLength > 1) stringToShow += cell.ignoreBrackets ? ', ' : ' (';
                if (index !== 0) {
                    if (index !== cellValuesLength - 1) stringToShow += ', ';
                    else if (!cell.ignoreBrackets) stringToShow += ')';
                }
            });

            return stringToShow;
        },
        getIndividualCellComponentValue (cellType, cellValue, cell, suffixPrefix, isExcel) {
            if (cellValue == null) return '-';
            switch (cellType) {
            case 'number':
                const showUnits = this.tableUnitValue === 'cell' || (this.tableUnitValue === 'header' && cell.showUnitsEvenWhenInHeader);

                if (cellValue === '' || isNaN(cellValue)) return '-';
                if (cell.isMw) {
                    cellValue = this.convertCellValueMw(cellValue);
                }
                if (cell.convertCellValueToDuration) {
                    cellValue = this.convertDoubleToDuration(cellValue);
                    return cellValue;
                }
                return `${
                    !showUnits || cell.prefix == null || !suffixPrefix ? '' : cell.prefix
                }${suffixPrefix ? this.formatNumber(cellValue, cell) : cellValue}${
                    !showUnits || cell.suffix == null || !suffixPrefix ? '' : cell.suffix
                }`;
            case 'text':
                return cellValue;
            case 'date':
                const timeFormat = this.timeFormatValue === '12h' ? 'h:mm a' : 'H:mm';
                switch (cell.dateFormat) {
                case 'Month':
                    return this.$moment.unix(cellValue).format('MMMM YYYY');
                case 'Day':
                    return this.$moment.unix(cellValue).format('Do MMMM YYYY');
                case 'Minute':
                    return this.$moment.unix(cellValue).format(`Do MMMM YYYY, ${timeFormat}`);
                case 'Minute_NoDate':
                    return this.$moment.unix(cellValue).format(timeFormat);
                case 'Day_Short':
                    return this.$moment.unix(cellValue).format('Do MMM YYYY');
                case 'Day_Short_With_Minutes':
                    return isExcel ? this.$moment.unix(cellValue).format('DD/MM/YYYY H:mm') : this.$moment.unix(cellValue).format(`Do MMM YYYY, ${timeFormat}`);
                case 'Day_Short_With_Minutes_24_Hours':
                    return this.$moment.unix(cellValue).format('DD/MM/YYYY HH:mm');
                case 'Day_Short_With_Minutes_No_Year':
                    return this.$moment.unix(cellValue).format(`Do MMM, ${timeFormat}`);
                default:
                    break;
                }
                break;
            case 'time':
                return this.$moment.unix(cellValue).format('LT');
            default:
                console.error('unknown type of cell');
                return '';
            }
        },
        formatNumber (num, cell) {
            num = parseFloat(num);
            if (num === 0) {
                return 0;
            }

            num = this.hidePoundDecimals && cell.prefix === '£' ? Math.round(num) : this.threeSigFigs && num < 1 ? num.toPrecision(3) : Math.round((num + Number.EPSILON) * 100) / 100;
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        },

        closeFilter () {
            this.tableFilterBox.visible = false;
        },
        showFilter (header, event) {
            if ((!header.allowFilter && !header.allowSort) || this.lockedAccount || this.disableFilter) return;
            if (!this.tableFilterBox.visible) {
                this.currentHeaderOpen = header.id;
                if (this.$store.state.ui.slideOutOpen) {
                    this.$store.commit('toggleSlideOutMenu');
                }
                const filterWidth = 300;
                if (event.currentTarget.offsetLeft - this.$refs.scrollableTable.scrollLeft + filterWidth > this.$refs.table.parentElement.clientWidth) {
                    this.tableFilterBox.position.left = this.$refs.table.parentElement.clientWidth - filterWidth;
                } else {
                    this.tableFilterBox.position.left = event.currentTarget.offsetLeft - this.$refs.scrollableTable.scrollLeft;
                }

                let headerFound = this.findHeader(header.id);

                this.tableFilterBox.headerData = headerFound;
                this.tableFilterBox.uniqueData = header.uniqueValues;
                this.tableFilterBox.sampleDataPoint = this.tableDataData.length > 0
                    ? this.tableDataData[0].row[header.id]
                    : null;

                this.tableFilterBox.currentFilters = this.currentFiltersByHeading.hasOwnProperty(
                    header.id,
                )
                    ? this.currentFiltersByHeading[header.id]
                    : null;
                this.tableFilterBox.currentSortAsc = header.id == this.headerToSort.id
                    ? this.headerToSort.ascending
                    : null;
                if (this.currentFiltersByHeading.hasOwnProperty(header.id)) {
                    this.tableFilterBox.selectedOptions = this.currentFiltersByHeading[header.id].options.values;
                    this.tableFilterBox.group = this.currentFiltersByHeading[header.id].options.group;
                } else {
                    this.tableFilterBox.selectedOptions = [];
                    this.tableFilterBox.group = null;
                }

                this.tableFilterBox.visible = true;
            }
        },
        selectRow (row, selected) {
            if (selected) this.selectedRows.push(row);
            else this.selectedRows.splice(this.selectedRows.indexOf(row), 1);

            this.$emit('rowsSelected', this.selectedRows);
        },
        convertCellValueMw (cellValue) {
            return this.$store.state.user.leaderboardCapacityInMwh ? cellValue * 0.5 : cellValue;
        },
        convertDoubleToDuration (cellValue) {
            // Define conversion constants
            const minutesInYear = 60 * 24 * 365;
            const minutesInMonth = 60 * 24 * 30;
            const minutesInDay = 60 * 24;
            const minutesInHour = 60;

            // Calculate the total number of minutes
            let minutes = cellValue * 60;

            // Create an array of time frame strings
            let timeFrames = [];

            // Calculate the total number of days, months, and years
            const years = minutes / minutesInYear;
            if (years >= 1) {
                timeFrames.push(`${Math.floor(years)}yr`);
                minutes -= Math.floor(years) * minutesInYear;
            }
            const months = minutes / minutesInMonth;
            if (months >= 1) {
                timeFrames.push(`${Math.floor(months)}mo`);
                minutes -= Math.floor(months) * minutesInMonth;
            }

            const days = minutes / minutesInDay;
            if (days >= 1) {
                timeFrames.push(`${Math.floor(days)}d`);
                minutes -= Math.floor(days) * minutesInDay;
            }

            const hours = minutes / minutesInHour;
            if (hours >= 1) {
                minutes -= Math.floor(hours) * minutesInHour;
                if (timeFrames.length < 3) {
                    timeFrames.push(`${Math.floor(hours)}hr`);
                }
            }

            if (minutes > 0 && timeFrames.length < 3) {
                timeFrames.push(`${Math.floor(minutes)}m`);
            }
            let output = timeFrames.slice(0, 3).join(' ');

            return output;
        },
        rowClicked (row, el) {
            if (this.showRowSelectors) {
                row.rowSelected = !row.rowSelected;
                this.selectRow(row, el);
                return;
            }

            if (row.clickEventUrl != null) {
                this.$router.push(row.clickEventUrl);
            } else {
                Object.keys(row.row).forEach((key) => {
                    if (row.row[key].isPlant) {
                        this.$emit('plantSelect', row.row[key].alternativeHiddenString || row.row[key].values[0]);
                    }
                });
            }
            this.$emit('rowSelect', row);
        },
    },
};
</script>

<style lang="scss" scoped>
.editDownloadIcon {
    color: $primary;
    font-size: 22px;
    margin-right: 15px;
    cursor: pointer;
}
.download {
    position: absolute;
    right: -10px;
    top: -10px;
    font-weight: 500;
    cursor: pointer;
    @include responsive("sm") {
        display: none;
    }
}
.tableWrapper {
    padding-top: 0;
    &.curved {
        border-radius: $border-radius $border-radius 0 0;
        border: var(--light) solid 4px;
    }
    @include responsive("sm") {
        max-height: 100% !important;
        min-height: 100% !important;
    }
    .capped {
        font-size: 20px;
        font-weight: 600;
        color: var(--primaryFont);
        @include responsive("sm") {
            font-size: 15px;
        }
        .link {
            color: $primary;
            text-decoration: none;
        }
    }
}
.tableFooter {
    border-radius: 0 0 $border-radius $border-radius;
    background-color: var(--light);
    display: flex;
    justify-content: space-between;
    &.floatingFooter {
        .editDownloadIcon {
            position: absolute;
            bottom: -5px;
            right: -20px;
            background-color: $primary;
            padding: 7px;
            color: var(--primaryFont);
            border-radius: 20px;
            font-size: 18px;
        }
    }
    .defaultFooter {
        display: flex;
        // justify-content: space-between;
    }
    .button {
        margin: 0px 20px;
        padding: 5px 10px;
        height: 25px;
        line-height: 15px;
    }
}
.relative {
    position: relative;
    height: 100%;
}

.infoIcon {
	color: $primary;
}

.filter {
    position: absolute;
    z-index: 3;
}

@-webkit-keyframes flash1 {
  from { background-color: $ALTERNATIVE1; }
  to { background-color: inherit; }
}
@-moz-keyframes flash1 {
  from { background-color: $ALTERNATIVE1; }
  to { background-color: inherit; }
}
@-o-keyframes flash1 {
  from { background-color: $ALTERNATIVE1; }
  to { background-color: inherit; }
}
@keyframes flash1 {
  from { background-color:  $ALTERNATIVE1; }
  to { background-color: inherit; }
}
.flash1 {
  -webkit-animation: flash1 2s 1; /* Safari 4+ */
  -moz-animation:    flash1 2s 1; /* Fx 5+ */
  -o-animation:      flash1 2s 1; /* Opera 12+ */
  animation:         flash1 2s 1; /* IE 10+ */
}
@-webkit-keyframes flash2 {
  from { background-color:  $ALTERNATIVE1; }
  to { background-color: inherit; }
}
@-moz-keyframes flash2 {
  from { background-color:  $ALTERNATIVE1; }
  to { background-color: inherit; }
}
@-o-keyframes flash2 {
  from { background-color:  $ALTERNATIVE1; }
  to { background-color: inherit; }
}
@keyframes flash2 {
  from { background-color:  $ALTERNATIVE1; }
  to { background-color: inherit; }
}
.flash2 {
  -webkit-animation: flash2 2s 1; /* Safari 4+ */
  -moz-animation:    flash2 2s 1; /* Fx 5+ */
  -o-animation:      flash2 2s 1; /* Opera 12+ */
  animation:         flash2 2s 1; /* IE 10+ */
}

.fancyTable {
    .infoIcon {
        display: none;
    }
    .table {
        color: var(--primaryFont);
        font-size: 13px;
        margin-top: -10px;
        @include responsive("sm") {
			font-size: 12px !important;
            margin-top: 0px;
		}
        @include responsive("xl") {
			font-size: 12px;
            border-spacing: 0 5px;
		}
        .tr {
            border-radius: 6px;
            .th {
                padding: 10px 8px;
                text-align: left;
                font-size: 20px;
                font-weight: 700;
                &.first {
                    text-align: center;
                }
                @include responsive("sm") {
                    font-size: 12px !important;
                    padding: 5px;
                }
            }
            .td {
                padding: 10px 8px;
                border-bottom-width: 2px;
                &.iconHolder {
                    padding: 4px 0px 4px 8px;
                }
                .iconCell {
                    font-size: 20px;
                    color: $ALTERNATIVE2;
                }
                @include responsive("sm") {
                    padding: 5px;
                }
            }
        }
    }
}

.table {
	display: table;
	table-layout: auto;
	font-size: 12px;
	width: calc(100%);
    border-collapse: separate;
    border-radius: $border-radius;

	.tr {
		display: table-row;
        &.selected {
            .td {
                background: $ALTERNATIVE1;
            }
        }
		.nowrap {
			white-space: nowrap;
        }
    }
	.td,
	.th {
		display: table-cell;
		padding: 5px;
        &.stickLeft {
            @include responsive('smUp') {
                position: sticky;
                left: 0;
            }
        }
	}
	.th {
		position: sticky;
		font-weight: 500;
		background: var(--dark);
        border: none;
        @include responsive('sm') {
            text-align: left;
        }
        &.lastChild, &.first {
            border-right: 4px var(--tableRow) solid !important;
        }
        &.disabled {
            color: var(--primaryFont);
            cursor: not-allowed;
        }
        z-index: 2;
        &.curveFirst {
            border-radius: $border-radius 0 0 0;
        }
		.filterSort {
			color: $primary;
		}
        &.stickLeft {
            @include responsive('smUp') {
                z-index: 3;
            }
        }
        &.first {
            top: 0;
        }
        &.second {
            // top: 26px;
        }
        &:first-of-type {
            border-left-width: 4px;
        }
        &:last-of-type {
            border-right-width: 4px;
        }
	}
	.td {
		border-bottom: 1px solid var(--dark);
		&.flex-between {
			display: flex;
			justify-content: space-between;
            @include responsive('sm') {
                display: table-cell;
            }
		}
        &.stickLeft {
            @include responsive('smUp') {
                z-index: 1;
            }
        }
	}
    .clickable {
        color: $primary;
    }
	.hoverable {
		cursor: pointer;
		&:hover {
			.td {
				background-color: var(--dark) !important;
                &.light {
                    background-color: var(--light) !important;
                }
			}
		}
	}
    .showCursor {
		cursor: pointer;
	}
	.selectedRow {
		background: $ALTERNATIVE1TINT1 !important;
	}
	.tableHeader {
		background: $dmMed !important;
	}
}

.dark {
	.download {
		color: white;
	}
}
</style>
