var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modalWrapper" }, [
    _c("div", { staticClass: "modal", on: { click: _vm.close } }),
    _c(
      "div",
      { staticClass: "card confirm" },
      [
        _c("i", { staticClass: "icofont-ui-close", on: { click: _vm.close } }),
        _c("h1", [_vm._v(_vm._s(_vm.title))]),
        _vm.modalType === "licenseNoLogout"
          ? [
              _c("p", [
                _vm._v(
                  "This page requires a registered Enact account, with a valid licence."
                ),
              ]),
              _c("p", [
                _vm._v(
                  "If you have an Enact licence, then please log-in in the upper-right of your screen."
                ),
              ]),
              _vm._m(0),
              _c("p", [
                _vm._v(
                  " Closing this pop-up will redirect you to the home-page. "
                ),
              ]),
            ]
          : _vm._e(),
        _vm.modalType === "expiredToken"
          ? [
              _c("p", [_vm._v("Your access token for Enact has expired.")]),
              _c("p", [
                _vm._v(
                  "This may be due to your computer having been asleep for an extended period of time, your internet having a brief outage, or your access being revoked."
                ),
              ]),
              _c("p", [
                _vm._v(
                  "In order for Enact to continue to work, we require you to please refresh your page so you can log-in again."
                ),
              ]),
              _c("p", [_vm._v(" Closing this pop-up will refresh the page. ")]),
            ]
          : _vm._e(),
        _vm.modalType === "asleep"
          ? [
              _c("p", [
                _vm._v(
                  "Your browser has been asleep for an extended period of time, and you may have missed real-time updates to your data."
                ),
              ]),
              _c("p", [
                _vm._v(
                  "This may be due to your computer having been asleep, your internet having a brief outage, or a policy by your company."
                ),
              ]),
              _c("p", [
                _vm._v(
                  "In order to ensure your Enact data is up-to-date, we recommend you refresh your browser."
                ),
              ]),
            ]
          : _vm._e(),
        _vm.modalType === "release"
          ? [
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(_vm.$store.state.ui.accessDeniedModal.text),
                },
              }),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" Otherwise please visit our "),
      _c("a", { attrs: { href: "https://lcpenact.com", target: "_blank" } }, [
        _vm._v("Product Page"),
      ]),
      _vm._v(" to find out more about what Enact can do for you. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }